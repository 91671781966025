import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../../Page";
import ActionCreator from "../../ActionCreator";
import ArticleListItem from "../../Components/ArticleListItem";
import * as Widget2 from "../../Components/Widget2";

class NewsListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news: null,
      showSpinner: true
    };
  }

  componentDidMount() {
    this._fetchRecords();
  }

  render() {
    let { news, showSpinner } = this.state;

    return (
      <Wrapper>
        <Widget2.Banner img="/images/banner06.png" css="margin-bottom: 85px;">
          <div className="mist">
            <div className="title">最新消息</div>
          </div>
        </Widget2.Banner>

        <Container>
          {(() => {
            if (showSpinner) {
              return (
                <Widget2.Center>
                  <Widget2.Spinner />
                </Widget2.Center>
              );
            }

            return (
              <div className="list">
                {news.results.map((item, i) => (
                  <ArticleListItem
                    key={"list-news-item" + i}
                    from={"news"}
                    data={item}
                    css="margin-bottom: 35px;"
                  />
                ))}
              </div>
            );
          })()}
        </Container>
      </Wrapper>
    );
  }

  _fetchRecords = () => {
    let { appActions } = this.props;

    appActions
      .getNewsList()
      .then(news => this.setState({ news }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };
}

const Wrapper = styled.div`
  padding-top: 100px;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1181px;
  padding: 20px;

  & > .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 414px) {
      justify-content: center;
    }
  }
`;

export default withPage(
  connect(
    null,
    ActionCreator
  )(NewsListPage)
);
