import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import ActionCreator from "../ActionCreator";

class ArticleListItem extends Component {
  render() {
    let { navActions, from, css, data } = this.props;

    if (data) {
      return (
        <Wrapper
          img={data.image}
          css={css}
          onClick={() => navActions.push(`/${from}_article?id=${data.id}`)}
        >
          <div className="text">
            <div className="date">
              {moment(data.created).format("YYYY/MM/DD")}
            </div>
            <div className="title">{data.name}</div>
            <div className="description">
              {data.description.length > 42
                ? data.description.slice(0, 42) + "..."
                : data.description}
            </div>
          </div>
          <div className="image" />
        </Wrapper>
      );
    }

    return "";
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;

  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  border-radius: 19px;
  max-width: 560px;
  width: 100%;
  height: 250px;
  cursor: pointer;

  & > .text {
    flex: 1;
    padding: 15px;
    overflow: hidden;

    & > .date {
      font-size: 14px;
      color: rgba(16, 29, 31, 0.6);
      letter-spacing: 1.4px;
    }

    & > .title {
      margin-bottom: 16px;
      font-weight: bold;
      font-size: 24px;
      color: #101d1f;
      letter-spacing: 2.4px;
    }

    & > .description {
      font-size: 16px;
      color: #101d1f;
      letter-spacing: 1.6px;
    }

    @media screen and (max-width: 768px) {
      & > .title {
        font-size: 14px;
      }

      & > .description {
        font-size: 14px;
      }
    }

    @media screen and (max-width: 414px) {
      & > .text {
        padding: 10px;
      }

      & > .date {
        font-size: 12px;
      }

      & > .title {
        font-size: 13px;
      }

      & > .description {
        font-size: 12px;
      }
    }
  }

  & > .image {
    border-top-right-radius: 19px;
    border-bottom-right-radius: 19px;
    max-width: 280px;
    max-height: 250px;
    width: 100%;
    height: 100%;

    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 1024px) {
      max-width: 200px;
    }

    @media screen and (max-width: 768px) {
      max-width: 180px;
    }

    @media screen and (max-width: 600px) {
      max-width: 120px;
    }

    @media screen and (max-width: 414px) {
      max-width: 150px;
    }

    @media screen and (max-width: 320px) {
      max-width: 125px;
    }
  }

  @media screen and (max-width: 1024px) {
    max-width: 483px;
  }

  @media screen and (max-width: 768px) {
    max-width: 355px;
  }

  @media screen and (max-width: 600px) {
    max-width: 271px;
  }

  @media screen and (max-width: 414px) {
    max-width: 100%;
  }

  ${props => props.css};
`;

export default connect(
  null,
  ActionCreator
)(ArticleListItem);
